// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as DisablingCover from "../../../shared/components/DisablingCover.bs.js";
import * as MarkdownEditor from "../../../shared/components/MarkdownEditor.bs.js";
import * as CoursesStudents__CoachNote from "../types/CoursesStudents__CoachNote.bs.js";
import * as CoursesStudents__CoachNoteShow from "./CoursesStudents__CoachNoteShow.bs.js";

import "./CoursesStudents__StudentOverlay.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesStudents__CoachNotes";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation CreateCoachNoteMutation($studentId: ID!, $note: String!)  {\ncreateCoachNote(studentId: $studentId, note: $note)  {\ncoachNote  {\nid  \nnote  \ncreatedAt  \nauthor  {\nid  \navatarUrl  \nname  \nfullTitle  \n}\n\n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.createCoachNote;
  var value$2 = value$1.coachNote;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.author;
    var tmp$1;
    if (value$3 == null) {
      tmp$1 = undefined;
    } else {
      var value$4 = value$3.avatarUrl;
      tmp$1 = {
        id: value$3.id,
        avatarUrl: !(value$4 == null) ? value$4 : undefined,
        name: value$3.name,
        fullTitle: value$3.fullTitle
      };
    }
    tmp = {
      id: value$2.id,
      note: value$2.note,
      createdAt: value$2.createdAt,
      author: tmp$1
    };
  }
  return {
          createCoachNote: {
            coachNote: tmp
          }
        };
}

function serialize(value) {
  var value$1 = value.createCoachNote;
  var value$2 = value$1.coachNote;
  var coachNote;
  if (value$2 !== undefined) {
    var value$3 = value$2.author;
    var author;
    if (value$3 !== undefined) {
      var value$4 = value$3.fullTitle;
      var value$5 = value$3.name;
      var value$6 = value$3.avatarUrl;
      var avatarUrl = value$6 !== undefined ? value$6 : null;
      var value$7 = value$3.id;
      author = {
        id: value$7,
        avatarUrl: avatarUrl,
        name: value$5,
        fullTitle: value$4
      };
    } else {
      author = null;
    }
    var value$8 = value$2.createdAt;
    var value$9 = value$2.note;
    var value$10 = value$2.id;
    coachNote = {
      id: value$10,
      note: value$9,
      createdAt: value$8,
      author: author
    };
  } else {
    coachNote = null;
  }
  var createCoachNote = {
    coachNote: coachNote
  };
  return {
          createCoachNote: createCoachNote
        };
}

function serializeVariables(inp) {
  return {
          studentId: inp.studentId,
          note: inp.note
        };
}

function makeVariables(studentId, note, param) {
  return {
          studentId: studentId,
          note: note
        };
}

var CreateCoachNotesMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var CreateCoachNotesMutation_Graphql_error = include.Graphql_error;

var CreateCoachNotesMutation_decodeNotification = include.decodeNotification;

var CreateCoachNotesMutation_decodeObject = include.decodeObject;

var CreateCoachNotesMutation_decodeNotifications = include.decodeNotifications;

var CreateCoachNotesMutation_decodeErrors = include.decodeErrors;

var CreateCoachNotesMutation_flashNotifications = include.flashNotifications;

var CreateCoachNotesMutation_sendQuery = include.sendQuery;

var CreateCoachNotesMutation_query = include.query;

var CreateCoachNotesMutation_fetch = include.$$fetch;

var CreateCoachNotesMutation = {
  CreateCoachNotesMutation_inner: CreateCoachNotesMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreateCoachNotesMutation_Graphql_error,
  decodeNotification: CreateCoachNotesMutation_decodeNotification,
  decodeObject: CreateCoachNotesMutation_decodeObject,
  decodeNotifications: CreateCoachNotesMutation_decodeNotifications,
  decodeErrors: CreateCoachNotesMutation_decodeErrors,
  flashNotifications: CreateCoachNotesMutation_flashNotifications,
  sendQuery: CreateCoachNotesMutation_sendQuery,
  query: CreateCoachNotesMutation_query,
  $$fetch: CreateCoachNotesMutation_fetch,
  make: make
};

function saveNote(studentId, setState, state, addNoteCB) {
  Curry._1(setState, (function (state) {
          return {
                  newNote: state.newNote,
                  saving: true
                };
        }));
  Curry._3(make, undefined, undefined, {
            studentId: studentId,
            note: state.newNote
          }).then(function (response) {
          var note = response.createCoachNote.coachNote;
          if (note !== undefined) {
            var newNote = CoursesStudents__CoachNote.makeFromJs(Caml_option.valFromOption(note));
            Curry._1(addNoteCB, newNote);
            Curry._1(setState, (function (param) {
                    return {
                            newNote: "",
                            saving: false
                          };
                  }));
          } else {
            Curry._1(setState, (function (state) {
                    return {
                            newNote: state.newNote,
                            saving: false
                          };
                  }));
          }
          return Promise.resolve(undefined);
        }).catch(function (_error) {
        Curry._1(setState, (function (state) {
                return {
                        newNote: state.newNote,
                        saving: false
                      };
              }));
        return Promise.resolve(undefined);
      });
  
}

function updateCoachNoteCB(setState, newNote) {
  return Curry._1(setState, (function (state) {
                return {
                        newNote: newNote,
                        saving: state.saving
                      };
              }));
}

function saveNoteButtonText(title, iconClasses) {
  return React.createElement("span", undefined, React.createElement(FaIcon.make, {
                  classes: iconClasses + " mr-2"
                }), title);
}

function CoursesStudents__CoachNotes(Props) {
  var studentId = Props.studentId;
  var coachNotes = Props.coachNotes;
  var hasArchivedNotes = Props.hasArchivedNotes;
  var addNoteCB = Props.addNoteCB;
  var removeNoteCB = Props.removeNoteCB;
  var userId = Props.userId;
  var match = React.useState(function () {
        return {
                newNote: "",
                saving: false
              };
      });
  var setState = match[1];
  var state = match[0];
  return React.createElement("div", {
              className: "mt-3 text-sm"
            }, React.createElement("label", {
                  className: "font-semibold text-sm block mb-1",
                  htmlFor: "course-students__coach-notes-new-note"
                }, tr(undefined, undefined, "new_note")), React.createElement(DisablingCover.make, {
                  disabled: state.saving,
                  message: "Saving...",
                  children: React.createElement(MarkdownEditor.make, {
                        value: state.newNote,
                        onChange: (function (param) {
                            return updateCoachNoteCB(setState, param);
                          }),
                        profile: /* Permissive */0,
                        textareaId: "course-students__coach-notes-new-note",
                        maxLength: 10000
                      })
                }), React.createElement("button", {
                  className: "btn btn-primary mt-2",
                  disabled: state.newNote.length < 1 || state.saving,
                  onClick: (function (param) {
                      return saveNote(studentId, setState, state, addNoteCB);
                    })
                }, state.saving ? saveNoteButtonText(tr(undefined, undefined, "saving"), "fas fa-spinner") : saveNoteButtonText(tr(undefined, undefined, "save_note"), "")), React.createElement("div", undefined, React.createElement("h6", {
                      className: "font-semibold mt-6"
                    }, tr(undefined, undefined, "all_notes")), ArrayUtils.isEmpty(coachNotes) ? React.createElement("div", {
                        className: "bg-gray-50 rounded text-center p-4 md:p-6 items-center justify-center mt-2"
                      }, React.createElement(Icon.make, {
                            className: "if i-long-text-light text-gray-800 text-base"
                          }), React.createElement("p", {
                            className: "text-xs font-semibold text-gray-600 mt-2"
                          }, hasArchivedNotes ? tr(undefined, undefined, "has_archived_notes") : tr(undefined, undefined, "no_notes"))) : null, $$Array.map((function (note) {
                        return React.createElement(CoursesStudents__CoachNoteShow.make, {
                                    note: note,
                                    userId: userId,
                                    removeNoteCB: removeNoteCB,
                                    key: CoursesStudents__CoachNote.id(note)
                                  });
                      }), CoursesStudents__CoachNote.sort(coachNotes))));
}

var make$1 = CoursesStudents__CoachNotes;

export {
  str ,
  tr ,
  CreateCoachNotesMutation ,
  saveNote ,
  updateCoachNoteCB ,
  saveNoteButtonText ,
  make$1 as make,
  
}
/*  Not a pure module */
